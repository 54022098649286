import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import Logo from './Logo';
import Navigation from './Navigation';

const StyledFooter = styled.footer`
  position: relative;
  text-align: center;
  padding: 2rem 1rem;
  background: ${({ theme }) => theme.radialGradient};
  color: ${({ theme }) => theme.colorTransparent};
  .logo {
    img {
      max-width: 60%;
    }
  }
  .legal-nav {
    display: block;
    a {
      margin-left: 1em;
      text-decoration: none;
      color: ${({ theme }) => theme.colorTransparent};
      transition: color 0.5s ease;
      :hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  @media screen and (min-width: 992px) {
    padding: 5em 0;
    .legal-nav {
      display: inline;
    }
  }
`;

const Footer = ({ theme = {} }) => (
  <StyledFooter>
    <Logo variant={theme.name} />
    <Navigation.Footer />
    <p>
      © 2021 Ingrid Jureit - Alle Rechte vorbehalten
      <span className="legal-nav">
        <Link to="/impressum">Impressum</Link>
        <Link to="/datenschutz">Datenschutz</Link>
      </span>
    </p>
  </StyledFooter>
);

Footer.propTypes = {
  theme: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default withTheme(Footer);
