import PropTypes from 'prop-types';
import React from 'react';

const Logo = ({ variant = 'dark' }) => {
  const name = variant === 'dark' ? 'logo.png' : 'logo-variant.png';
  return (
    <div className="logo">
      <img src={`/img/${name}`} alt="Ingrid Jureit" />
    </div>
  );
};

const LogoIcon = () => (
  <svg className="logo-icon" xmlns="http://www.w3.org/2000/svg" width="145.386" height="194.9" viewBox="0 0 145.386 194.9">
    <g transform="translate(-1309.589 -1585.178)">
      <path d="M57.13-23.64A12,12,0,0,0,64.813-26.5a13.649,13.649,0,0,0,4.728-6.993L97.515-137.9h35.46L101.258-19.7A26.114,26.114,0,0,1,91.7-5.812Q84.316,0,76.239,0h-88.65l6.5-23.64Z" transform="translate(1322 1780.078)" fill="white" />
      <path d="M24.625-137.9h35.46L23.049,0h-35.46Z" transform="translate(1337 1723.078)" fill="white" />
    </g>
  </svg>
);

Logo.propTypes = {
  variant: PropTypes.string,
};

Logo.Icon = LogoIcon;

export default Logo;
