const vita = [
  {
    key: 1,
    date: '1944',
    text: `
      <p>
        in Hofheim am Taunus geboren
      </p>
    `,
  },
  {
    key: 2,
    date: 'seit 1970',
    text: `
      <p>
        langjährige Tätigkeit als Bürovorsteherin<br class="d-none d-sm-inline" />
        in einer Anwalts- und Notariatskanzlei
      </p>
    `,
  },
  {
    key: 3,
    date: '1974',
    text: `
      <p>
        Studium der Kunstgeschichte
      </p>
    `,
  },
  {
    key: 4,
    date: '1978 - 1980',
    text: `
      <p>
        zahlreiche Lehrgänge in allen Techniken der Malerei und Zeichnung<br />
        Studienreisen: Frankreich, Spanien, Italien, Afrika, China
      </p>
    `,
  },
  {
    key: 5,
    date: 'seit 1980',
    text: `
      <p>
        freischaffend
      </p>
    `,
  },
  {
    key: 6,
    date: '1981 - 1984',
    text: `
      <p>
        Fortentwicklung durch weitere Lehrgänge in der Druckgraphik<br />
        (Lithographie, Holzschnitt und Radierung)<br />
        Einrichtung eines 2. Ateliers in Frankreich
      </p>
    `,
  },
  {
    key: 7,
    date: '1984',
    text: `
      <p>
        Preis der Ecole du Chinonais d'Art, Chinon (Frankreich)
      </p>
    `,
  },
  {
    key: 8,
    date: '1988',
    text: `
      <p>
        Arbeit mit Schülern an verschiedenen Schulen
      </p>
      <p>
        Jurymitglied bei der Vergabe von Kunstpreisen
      </p>
    `,
  },
  {
    key: 9,
    date: '1993',
    text: `
      <p>
        Kulturpreis der Kreisstadt Hofheim am Taunus<br />
        - zusammen mit der Hofheimer Gruppe -
      </p>
    `,
  },
  {
    key: 10,
    date: 'seit 1994',
    text: `
      <p>
        Kursleiterin Kunstwerkstatt Königstein im Taunus
      </p>
    `,
  },
  {
    key: 11,
    date: '1995 - 2008',
    text: `
      <p>
        Lehraufträge an der Akademie für bildende Kunst Vulkaneifel, Steffeln/Eifel
      </p>
    `,
  },
  {
    key: 12,
    date: '2006 - 2023',
    text: `
      <p>
        Lehraufträge an der Kunstakademie Bad Reichenhall
      </p>
    `,
  },
];

export default vita;
