const set = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const get = (key) => {
  const value = window.localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return '';
};

export default {
  set,
  get,
};
