import React from 'react';

const Icons = {};

const Cart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.125" height="17" viewBox="0 0 19.125 17">
    <path
      id="shopping-cart-solid"
      data-name="shopping-cart-solid"
      d="M17.535,10,19.1,3.1a.8.8,0,0,0-.777-.973H5.286L4.982.637A.8.8,0,0,0,4.2,0H.8A.8.8,0,0,0,0,.8v.531a.8.8,0,0,0,.8.8h2.32l2.332,11.4a1.86,1.86,0,1,0,2.226.284h6.961a1.859,1.859,0,1,0,2.112-.345l.183-.806a.8.8,0,0,0-.777-.973H7.242l-.217-1.063h9.733A.8.8,0,0,0,17.535,10Z"
      fill="#fff"
    />
  </svg>
);

const Paperplane = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.05" height="18.056" viewBox="0 0 18.05 18.056">
    <path
      id="paper-plane-solid"
      d="M16.793.083.45,9.511a.847.847,0,0,0,.078,1.523l3.748,1.573L14.406,3.68a.211.211,0,0,1,.3.293L6.215,14.321v2.838a.846.846,0,0,0,1.5.557l2.239-2.725,4.393,1.841a.849.849,0,0,0,1.164-.642L18.048.958A.846.846,0,0,0,16.793.083Z"
      transform="translate(-0.01 0.031)"
      fill="#fff"
    />
  </svg>
);

const Contrast = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
    <g id="contrast" transform="translate(-1571 -931)">
      <g id="square" data-name="square" transform="translate(1571 931)" stroke="#fff" strokeWidth="2">
        <rect width="37" height="37" stroke="none" />
        <rect x="1" y="1" width="35" height="35" fill="none" />
      </g>
      <path id="path" data-name="path" d="M37,0V37H0Z" transform="translate(1571 931)" fill="#fff" />
    </g>
  </svg>
);

const Burger = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="20" viewBox="0 0 32 20">
    <g id="burger" transform="translate(-149.5 -525.5)">
      <line id="Linie_4" data-name="Linie 4" x2="32" transform="translate(149.5 527.5)" fill="none" stroke="#fff" strokeWidth="4" />
      <line id="Linie_5" data-name="Linie 5" x2="32" transform="translate(149.5 535.5)" fill="none" stroke="#fff" strokeWidth="4" />
      <line id="Linie_6" data-name="Linie 6" x2="32" transform="translate(149.5 543.5)" fill="none" stroke="#fff" strokeWidth="4" />
    </g>
  </svg>
);

const Next = () => (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-right" className="svg-inline--fa fa-long-arrow-alt-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" />
  </svg>
);

const Prev = () => (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
  </svg>
);

Icons.Cart = Cart;
Icons.Paperplane = Paperplane;
Icons.Contrast = Contrast;
Icons.Burger = Burger;
Icons.Next = Next;
Icons.Prev = Prev;

export default Icons;
