/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import Page from '../components/Page';
import datenschutz from '../content/datenschutz';

const StyledContainer = styled(Container)`
  padding: 5rem 0;
  h1 {
    font-size: 3.75rem;
    margin-top: 5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 2rem;
    th {
      text-align: left;
      padding: 0.5rem;
      border-bottom: 1px solid white;
    }
    td {
      padding: 0.5rem;
      border-bottom: 1px solid white;
    }
  }
`;

const Datenschutz = ({ show = false }) => (
  <Page show={show}>
    <StyledContainer>
      <Row className="justify-content-center">
        <Col lg={10}>
          <div dangerouslySetInnerHTML={{ __html: datenschutz }} />
        </Col>
      </Row>
    </StyledContainer>
  </Page>
);

Datenschutz.propTypes = {
  show: PropTypes.bool,
};

export default Datenschutz;
