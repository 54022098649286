const werkstattbuecher = [
  {
    key: 1,
    img: 'schichtbilder-aus-dem-emotionalen-gedaechnis.jpg',
    title: 'Ingrid Jureit – Schichtbilder aus dem emotionalen Gedächtnis',
    text: `
      <p>
        106 Seiten, davon 55 Abbildungen
        Sachbuch Kunst Auflage 2000
        Erscheinungsmonat: Oktober 2008
      </p>
      <p>
        ISBN: 978-3-00-025914-2<br />
        ju-art-verlag
      </p>
      <p>
        Rechnungsbetrag: 25,00 € (Endbetrag)
      </p>
    `,
  },
  {
    key: 2,
    img: 'bilderzyklus-2009-2010_tagebuchnutizen.jpg',
    title: 'Ingrid Jureit – Bilderzyklus 2009 - 2010 Tagebuchnotizen',
    text: `
      <p>
        100 Seiten, davon 49 Abbildungen<br />
        Auflage 1000
      </p>
      <p>
        Rechnungsbetrag: 25,00 € (Endbetrag)
      </p>
    `,
  },
  {
    key: 3,
    img: 'arbeiten-auf-papier.jpg',
    title: 'Katalogtitel: Ingrid Jureit – Arbeiten auf Papier',
    text: `
      <p>
        59 Seiten, davon 24 Abbildungen<br />
        Auflage 1000<br />
        Erscheinungsmonat: März 2007
      </p>
        Rechnungsbetrag: 10,00 € (Endbetrag)
      </p>
    `,
  },
  {
    key: 4,
    img: 'hommage-a-edvard-munch-2012.jpg',
    title: 'Ingrid Jureit – Hommage à Edvard Munch 2012',
    text: `
      <p>
        88 Seiten, davon 45 Abbildungen<br />
        Auflage 200
      </p>
        Rechnungsbetrag: 25,00 € (Endbetrag)
      </p>
    `,
  },
  {
    key: 5,
    img: 'werkstattbuch-nr-1_jahrgang-2013.jpg',
    title: 'Ingrid Jureit Werkstattbuch <br />Nr. 1 Jahrgang 2013',
    text: `
      <p>
        50 Seiten davon 39 Bilder
      </p>
        Rechnungsbetrag: 20,00 € (Endbetrag)
      </p>
    `,
  },
  {
    key: 6,
    img: 'werkstattbuch-nr-2_jahrgang-2014.jpg',
    title: 'Ingrid Jureit Werkstattbuch <br />Nr. 2 Jahrgang 2014',
    text: `
      <p>
        66 Seiten davon 45 Bilder
      </p>
        Rechnungsbetrag: 20,00 € (Endbetrag)
      </p>
    `,
  },
  {
    key: 7,
    img: 'werkstattbuch-nr-3_jahrgang-2015.jpg',
    title: 'Ingrid Jureit Werkstattbuch <br />Nr. 3 Jahrgang 2015',
    text: `<p>
        68 Seiten davon 40 Bilder
      </p>
      <p>
        Rechnungsbetrag: 20,00 € (Endbetrag)
      </p>
    `,
  },
  {
    key: 8,
    img: 'werkstattbuch-nr-4_jahrgang-2017.jpg',
    title: 'Ingrid Jureit Werkstattbuch <br />Nr. 4 Jahrgang 2017',
    text: `
    <p>
      72 Seiten davon 30 Bilder
    </p>
    <p>
      Rechnungsbetrag: 20,00 € (Endbetrag)
    </p>
    `,
  },
];

export default werkstattbuecher;
