import yup from './yup';

const initialValues = {
  email: '',
  message: '',
  subject: '',
  consent: false,
  token: '',
};

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  message: yup.string().required('Bitte geben Sie einen Betreff an.'),
  subject: yup.string().required('Bitte geben Sie eine Mitteilung ein.'),
  consent: yup.bool().oneOf([true], 'Bitte stimmen Sie der Datenverarbeitung zu.'),
  token: yup.string().required('Bitte bestätigen Sie die Captcha Abfrage.'),
});

export default {
  initialValues,
  validationSchema,
};
