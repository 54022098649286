const text02 = `
<h2>Mittelbayerische - Kultur</h2>
<p>Artikel vom 22.04.2013</p>
<p>
  Menschen als Energiefelder<br />
  Die Künstlerin und Kunstpädagogin Ingrid Jureit zeigt in ihre Werke in der Schau <br />
  „Der Mensch im Bild“ in der Produzentengalerie Landshut.
</p>
<p>
  Von Michaela Schabel, MZ
</p>
<p>
  Landshut. Wie Energiefelder heben sich die Menschen in Ingrid Jureits Bildern aus dem
  Hintergrund ab. Farbknäuel formieren sich in linear begrenzten Körpern energetisch,
  leuchten im Umfeld auf. Nicht die reale Person ist im Vordergrund. Längst hat die
  Künstlerin die Porträtmalerei hinter sich gelassen. Sie interessiert die Vielschichtigkeit
  eines Menschen.
</p>
<p>
  Mit großer Intuition erlebt Ingrid Jureit ihren Alltag, ihre Begegnungen. In ihren Tagebuchnotizen sammelt sie
  besondere Eindrücke, die sie später zum Malen inspirieren und wonach sie ihre Bilder benennt. Die Tagebücher helfen in
  die Atmosphäre der Begegnungen noch einmal abzutauchen. Ihre Bilder sind gelebte Bilder.
</p>
<p>
  Farben werden Ausdruck seelischer Stimmungen, unterschiedliche Farbflächen überlagern sich, machen bipolaren
  Spannungen eines Menschen, die Energiemixtur eines Menschen im Mitmenschen, in der Gruppe und im Umfeld sichtbar.
  Positive Energien verstärken sich in zärtlichen Umarmungen, verdichten sich in der Menschengruppe zu Kraftfeldern, die
  Geborgenheit, aber auch Abgrenzung vermitteln. Körper in Bewegung vermitteln tänzerische Eleganz und Dymnamik.
</p>
<p>
  Die Bilder erschließen sich nicht sofort. Nur schemenhaft fügt sich das lineare Gefüge vor oft erdigen Farbnuancen zur
  menschlichen Gestalt: immer nackt mit großen kraftvollen Händen, muskulösen Beinen, die Frauen mit wallenden Haaren.
  In der Ruhe der Betrachtung entdeckt man Schicht für Schicht die energetische Kraft der dargestellten Menschen, mehr
  noch der dargestellten Situationen. Das ist erlebte Kunst, die auf das Wesentliche zielt: die emotionale
  Befindlichkeit des Menschen.
</p>
<p>
  Ingrid Jureit, 1944 in Hofheim im Taunus geboren, ist nicht nur als Künstlerin deutschlandweit bekannt. Durch ihren
  Lehrauftrag an der Kunstakademie in Bad Reichenhall gibt sie ihr Können an jüngere Künstler weiter. Die sind
  begeistert, schwärmen von der großen Empathie der Künstlerin, mit der sie Talente fördert und die Vielschichtigkeit
  des Malens vermittelt.
</p>
<p>
  Zu sehen ist die Ausstellung noch bis 12. Mai, freitags bis sonntags von 15 bis 18 Uhr in der Produzentengalerie
  Landshut, Seligenthaler Str. 22. Am 4. Mai zeigt Ingrid Jureit um 16 Uhr einen Film über ihr künstlerisches Schaffen.
</p>
`;

export default text02;
