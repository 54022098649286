const textRedeVonStefanieBickel = `
<h4>Rede von Stefanie Bickel</h4>
<p>Kunsthistorikerin</p>
<h2>Zur Ausstellungseröffnung Zyklus Tagebuchnotizen</h2>
<p>
  Schönen guten Abend meine Damen und Herren,
</p>
<p>
  ich freue mich sehr, Sie in dieser Ausstellung in der Englischen Kirche in Bad Homburg begrüßen zu dürfen. Die
  Englische Kirche ist einer der schönsten Ausstellungsräume, den ich kenne, aber er ist nicht ganz so leicht zu
  bespielen, denn es handelt sich bei diesem Raum um eine ehemalige Kirche. Die hohe Decke, der Lichteinfall und vor
  allem das Halbrund der Apsis schaffen eine besondere Atmosphäre. Es ist ein Raum, der obwohl er für kulturelle Zwecke
  genutzt wird, trotzdem eine stille, eine fast schon sakrale Konzentration ausstrahlt. Und das ist sicher etwas, das
  nicht für jede Kunst und für alle künstlerischen Positionen förderlich ist. Die Bilder von Ingrid Jureit aber bestehen
  in diesem Raum nicht nur, sie werden von ihm getragen und entfalten hier ihre ganz besondere Wirkung.
</p>
<p>
  Der Titel der Ausstellung sagt es Ihnen schon, sie sehen hier Tagebuchnotizen. Ungewöhnliche Tagebuchnotizen.
  üblicherweise sind Tagebücher nämlich eben das, nämlich Bücher, üblicherweise zeigt man diese nicht in der
  öffentlichkeit und üblicherweise sind Tagebücher in schriftlicher Form gehalten.
</p>
<p>
  All das ist hier anders, aber die Bilder sind Notizen und sie sind einzelnen Tagen zugeordnet. Anders, als bei einem
  schriftlichen Tagebucheintrag brauchen sie jedoch sehr viel länger, nämlich 2-3 Wochen, bis sie abgeschlossen sind.
  Sie beziehen sich jedoch trotzdem auf einen Tag. Das Datum dieses Tages können sie den kleinen Schildern unter den
  Bildern entnehmen, es sind gleichzeitig auch die Titel der Arbeiten. Die Bilder dokumentieren damit nicht nur diesen
  einen Tag, sondern speichern gleichzeitig auch die Erinnerungen der darauf folgenden Wochen, an diesen einen Tag.
</p>
<p>
  Wenn Sie die Ausstellung abschreiten, werden Sie feststellen, dass die Bilder in Gruppen geordnet sind, die nicht
  ihrer chronologischen Reihenfolge entsprechen. Stattdessen sind sie nach formalen Gesichtpunkten zusammengestellt.
  Format, Farbe, Duktus, Schwerpunktsetzung. Es war schnell klar, dass in dieser Ausstellung nicht der chronologische
  Ablauf des Entstehens sondern die Gesamtheit der Werkschau die Hängung diktiert.
</p>
<p>
  Auf den Leinwände, fast alle mit Acryl - manche auch in Mischtechnik gemalt, sehen sie stets Figuren. Die menschliche
  Gestalt ist bestimmend für diesen Zyklus von Ingrid Jureit. Die Körper werden durch die Umrisslinie definiert und sind
  ohne Binnenzeichnung ausgestaltet. Man kann keine Gesichter erkennen. Bei vielen Figuren ist auch unklar, ob sie
  männlich oder weiblich sind, bei anderen ist deutlich der weibliche Körper zu erkennen. Man kann eine reduzierte
  Farbigkeit beobachten. Rot, weiß, blau und ocker werden wiederholt in vielen einander überlagernden Schichten auf die
  Leinwand aufgetragen. Die meisten Linien, die die Körper definieren, sind dabei in einem braunrot gehalten. Ingrid
  Jureit empfindet diese Farbe als besonders passend, um die Körperlichkeit, die Fleischlichkeit ihrer Figuren zu
  umreißen. Es ist aber auch eine Farbe, die den Bildern etwas Altmeisterliches gibt.
</p>
<p>
  Diese Arbeiten spiegeln das innere Erleben der Künstlerin. Ihre Begegnung mit anderen Menschen, aber auch Ereignisse
  im äußeren und deren Auswirkungen auf das Innere. Insofern kann man die Darstellungen von Körpern in gewisser Weise
  als Selbstdarstellungen deuten. Allerdings nicht als Selbstportraits, denn sie zeigen keine individuellen Merkmale und
  die Künstlerin arbeitet auch ohne Fotografien oder Spiegel. Sie arbeitet auch ohne Modell. Die Körper ihrer Figuren,
  die Stellungen und Haltungen, die Gestiken und überstreckungen sind buchstäblich gewußt. Ingrid Jureit hat viele Jahre
  lang Aktkurse unterrichtet und sie kennt den menschlichen Körper. Sie hat die Linienführung, die Verkürzungen, die
  Größen und Proportionsverhältnisse aus allen denkbaren Perspektiven im Kopf. Sich halten, sich anlehnen, umarmen,
  getragen sein, liegend, hockend, lagernd, sich abstoßen, wegschieben – es finden sich hier die unterschiedlichsten
  Stellungen. Und da wir diese Haltungen aus eigenem Erleben und aus der eigenen Emotionalität her kennen, lesen wir
  auch eine emotionale Qualität aus den so reduzierten Körpern heraus. Nähe, Vertrauen, Einsamkeit, Lust, Angst und
  viele weitere Zustände werden erahnbar oder spürbar.
</p>
<p>
  Immer wieder wird eine deutliche überstreckung des Kopfes sichtbar, sowie eine Anwinkelung der Extremitäten. Diese
  Haltungen sind im Alltag eher selten und deshalb besonders symbolträchtig: beten, flehen, leiden und schmerzhafte
  Zustände mag man dadurch ausdrücken oder auch Lust im Augeblick des Orgasmus.
</p>
<p>
  Allerdings spiegeln die Arbeiten von Ingrid Jureit nicht nur das Wissen um Figur, sondern auch das innere Ringen um
  die richtige Figur. Viele Farbschichten, in Lasuren auf den Bildgrund aufgetragen, legen sich aufeinander. Ganze
  Gemälde entstehen, werden übermalt, entstehen erneut, schimmern durch den obersten Farbauftrag hindurch und erproben
  immer neu und immer wieder die eine stimmige, passende Figur, die eben jenen Tag, den sie reflektiert auch richtig
  wiedergibt. Wenn man nah an die Leinwände herantritt kann man das sehen. Da schimmern Linien durch. Sie implizieren
  Bewegung, Veränderung, das Vergehen der Zeit. Fallen, aufstehen, das sich heben oder senken des Körpers. Dieser
  Eindruck von Bewegung wird auch durch die steilen Perspektiven befördert. Die Körper sind zumeist sehr dicht an den
  Bildrand gerückt. Wenn das Bild als Fenster gedacht wird und die Fläche der Leinwand als Fensterscheibe, dann agieren
  die Figuren Ingrid Jureits unmittelbar vor diesem Fenster und einige von ihnen ragen sogar in unseren Raum, in den
  Raum des Betrachters hinein. Knie, Ellenbogen, Hände, Füße oder auch Hüften. Die Körper, die die Künstlerin in die
  Bilder stellt, rücken dem Betrachter auf den Leib. Gleichzeitig sind die Figuren auch schwer zu fixieren. Durch die
  vielen überlagerungen scheinen manche sich aufzulösen, sie entziehen sich dem definierenden Blick. Sie scheinen zu
  schweben und zu fliegen.
</p>
<p>
  Dabei ist zu beobachten, dass man die Bilder in drei Gruppen einteilen kann: Es gibt die Figur alleine, das Paar und
  die Bilder, auf denen Gruppen zu sehen sind. Man kann diese Einteilung vielleicht auch als Chiffre für die Thematik
  der Bilder sehen: Die Innenschau, das ALL-EINE-SEIEN, das sich spüren in Glück oder Verzweifelung, dann die Begegnung
  mit dem Anderen, dem Partner, dem Gegenüber und schließlich die Gemeinschaft, das Zusammenwirken in der Gruppe.
</p>
<p>
  Dabei sind die Figuren oft so in das Format gestellt, das der Rahmen des Bildes wie eine Kiste oder ein enger Raum
  fungiert. Der Rand der Leinwand ist eine räumliche Grenze wie eine Wand, an die die gemalten Körper sich anlehnen, die
  sie wegzudrücken scheinen, auf der sie stehen oder sitzen. Teilweise scheinen sie sogar gegen die Ebene der imaginären
  Fensterscheibe zu drücken, die die Trennung zwischen Bildraum und Betrachterraum markiert. Viele der Figuren sind in
  einer geschlossenen, schützenden Körperhaltung gezeigt. Sie implizieren eine Innenschau, ein sich spüren, das Selbst,
  das Sein, das Ich spüren, wahrnehmen, erleben. Würden die Figuren sich strecken, erheben und aufstehen, viele von
  Ihnen würden den Bildraum sprengen. Sie sind eigentlich größer als das Format. Die äußere Kante der Leinwände erzeugen
  somit den Eindruck teilweise einer Höhle oder sogar vielleicht einer Gebärmutter, in der sich die Figuren befinden.
  Sie sind in einem geschützten sonderbar autarkem Bildraum, der auf manchen Arbeiten sogar beengend wirken kann.
</p>
<p>
  Auf anderen wieder erscheint der Umraum weiter, größer, offener. Dieser Eindruck entsteht aber ausschließlich aus der
  räumlichen Wirkung der verwendeten Farben. Es gibt nirgends eine Hintergrundgestaltung. Nur die Stellung der Figuren
  impliziert ein Vorne und Hinten oder Oben und Unten. Man ahnt die vielen Schichten, bis zu dreißig können es sein, die
  sich jeweils auf dem Bildträger befinden. Diese erschienen teilweise als Farbraum, als Nebel, Traumbilder, Ahnungen
  oder Erinnerungen, aber sie beschreiben keine Außenwelt. Keine Gegenstände, Interieurs, Landschaftsandeutungen oder
  ähnliches finden sich auf diesen Arbeiten wieder. Das Umfeld der Figuren bleibt der Vorstellungskraft des Betrachters
  überlassen. Das Bild eröffnet eine Welt für sich, es übernimmt keine Elemente der Außenwelt.
</p>
<p>
  Das Zentrum der jeweiligen Komposition wird häufig durch einen Farbwirbel, eine durch viele sich verdichtende
  Pinselstriche entstandene Fläche gebildet. Dieser Schwerpunkt befindet sich bei vielen Figuren im Brustbereich und
  verweist somit auf das Herz, das Gefühl und die Empfindung. Da dieses formale Gewicht bei vielen der Bildern rot oder
  rotbraun gestaltet ist und sich häufig durch einen verworrenen Pinselstrich ein regelrechtes Farbnetz bildet, entsteht
  auch der Eindruck von Versehrtheit oder Verletzung bei den Figuren. Bei vielen Kompositionen ist dieses inhaltliche
  Zentrum aus der Symmetrie des Formates herausgerückt. Dadurch das die Schwerpunktsetzung an den Rändern stattfindet,
  entsteht auch eine Spannung im Format und es entsteht der Eindruck, als seinen die Figuren Ingrid Jureits dabei, in
  das Bild hinein oder aus ihm herauszusteigen, oder eben sich an seinen Rahmen anzulehnen.
</p>
<p>
  Schließlich bekommen die Bilder dadurch, dass sich die Figuren nackt, häufig mit blankem Schädel in einer Welt
  befinden, in der oben und unten, vorne und hinten allein nur durch die Haltung ihrer selbst und durch die
  perspektivische Verkürzung ihrer Glieder angegeben ist, etwas befremdliches. Sie lassen eine Innenwelt, eine innere
  Welt sichtbar werden, von der Ingrid Jureit mir in der Vorbesprechung zu dieser Ausstellung sagte: „Das sind alles
  intime Bilder.“
</p>
<p>
  Intime Bilder, die die Künstlerin mit uns teilt und die auch unsere intime Empfindung berühren können. Ich wünsche
  Ihnen viel Freude beim Sehen und Entdecken dieser Ausstellung!
</p>
<p>
  Vielen Dank für Ihre Aufmerksamkeit
</p>
<p>
  Stefanie Bickel, 10.06.2011
</p>
`;

export default textRedeVonStefanieBickel;
