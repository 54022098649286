import PropTypes from 'prop-types';
import React from 'react';

const VideoEmbed = ({
  video = {
    width: '854',
    height: '480',
    poster: '',
    autobuffer: true,
    controls: true,
  },
}) => (
  // eslint-disable-next-line jsx-a11y/media-has-caption
  <video
    width={video.width}
    height={video.height}
    poster={video.poster}
    autobuffer={video.autobuffer && video.autobuffer.toString()}
    controls={video.controls}
  >
    {video.sources && video.sources.map((src) => (
      <source key={src.key} src={src.url} type={src.type} />
    ))}
    <noscript>
      Leider unterstützt Ihr Browser weder HTML5 Video noch das Flash Format.
      Bitte laden Sie sich einen aktuellen Browser herunter.
    </noscript>
  </video>
);

VideoEmbed.propTypes = {
  video: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    poster: PropTypes.string,
    autobuffer: PropTypes.bool,
    controls: PropTypes.bool,
    sources: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
    })),
  }),
};

export default VideoEmbed;
