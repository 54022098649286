const text01 = `
<h5>Rede von Professor Dr. Horst Seller</h4>
<h2>Zur Ausstellungseröffnung in der Englischen Kirche in Bad Homburg v.d.H.</h2>
<p>vom 4. August bis 3. September 2017</p>
<p>
  Es ist immer recht problematisch über Bilder zu reden, weil es dabei unumgänglich ist, die in einem Bild gegebene
  Einheit von Inhalt und Form, von Bedeutung und ästhetischer Gestaltung zu zerstören. Über das >„Was“ ist gemalt< und
  das> „Wie“ ist es gemalt< kann nur separat gesprochen werden. Das Bild kann nicht als Einheit in Sprache übertragen
  werden. Ich möchte mit dem>„Was“ ist gemalt< beginnen. </p>
<p>
  Bei dem ersten Blick auf die hier ausgestellten Bilder sehen wir menschliche Figuren, Einzelfiguren, Paare oder
  Gruppen von Figuren in heftigen Gebärden, in den meisten Fällen über die ganze Bildfläche bis an den äußersten
  Bildrand ausgedehnt.
</p>
<p>
  Die Figuren sind eindeutig nicht mit einer zielgerichteten Handlung befasst, sondern sie verharren in einer
  anhaltenden Gebärde und werden damit zu einem Zeichen – einer Chiffre – für den Ausdruck einer seelischen Stimmung. Da
  die seelisch-geistige Energie immateriell und unsichtbar ist, kann sie nur auf diese Weise indirekt durch ihre
  Einwirkung auf die körperliche Gestalt sichtbar werden. Es ist ganz so, wie es schon Aristoteles in seinem Buch über
  die Seele beschrieben hat: „Der Körper ist die erste Verwirklichung der Seele.“
</p>
<p>
  Damit ist die Gestaltungsarbeit der Künstlerin im Grundsatz beschrieben: Es gelingt ihr auf eine geheimnisvolle Weise
  die Wahrnehmung eines für sie bedeutsamen Ereignisses in eine bildhafte mentale Repräsentation zu übertragen, die
  außerhalb einer sprachlichen Beschreibung liegt. Mit „bedeutsam“ ist hier die emotionale Komponente in der Reaktion
  auf ein Ereignis gemeint. Dieser emotionale Stimmungsgehalt bestimmt ihre weitere gedankliche Verarbeitung bis zu dem
  bildlichen Gestaltungsprozess.
</p>
<p>
  Das konkrete Ereignis als Auslöser für diesen gedanklichen Prozess wird nicht in einem Titel genannt und kann auch
  kaum aus dem Bild erraten werden. Damit wird dem Betrachter alle Offenheit gelassen, um aus seiner eigenen Erfahrung
  und Stimmungslage das Bild zu erforschen.
</p>
<p>
  In einigen dieser Bilder, die alle in den letzten Jahren entstanden sind und hier zum ersten Mal ausgestellt werden,
  besteht eine traurige bis verzweifelte Grundstimmung. Dies ist verständlich durch die nun schon seit Jahren ständig
  auf uns eindringenden Katastrophenmeldungen von Terror, Krieg, Flucht und Vertreibung. Die Bilder zeigen damit die
  enge Verbundenheit der Künstlerin mit dem Schicksal anderer Menschen und ihre empfindsame Reaktion auf inhumane
  Zustände, wo immer es ist auf der Welt.
</p>
<p>
  Bei der momentanen Situation in der Welt wird man erinnert an die Verszeilen von Bertolt Brecht aus den dreißiger
  Jahren des vorigen Jahrhunderts: „Was sind das für Zeiten, wo ein Gespräch über Bäume fast ein Verbrechen ist, weil es
  ein Schweigen über soviele Untaten einschließt.“
</p>
<p>
  Der Zeitpunkt der Wahrnehmung der für sie bedeutsamen Ereignisse ist mit dem Datum in den Bildlegenden angegeben. Dies
  ist also nicht das Datum der Fertigstellung des Bildes! Zwischen Wahrnehmung und Malen des Bildes können Tage, aber
  auch Wochen oder sogar Jahre vergehen. Die Ereignisse müssen sich demnach zu sehr stabilen „inneren Bildern“ in ihrem
  emotionalen Gedächtnis festgesetzt haben, wobei ich unter diesem Ausdruck „inneres Bild“ kein irgendwie geformtes
  Abbild der äußeren Wirklichkeit verstehe, sondern einen fließenden, sich ständig zeitlich verändernden gedanklichen
  Prozess. Dieser Prozess erlangt dann nach ausreichender Klärung die Schwelle zum Antrieb für die äußere
  Bildgestaltung, zum einen wohl für die eigene Kontrolle des gedanklichen Ergebnisses, zum anderen aber auch als Mittel
  zur Kommunikation mit dem zukünftigen Betrachter. Jedes Bild wird schließlich gemalt, um gesehen zu werden.
</p>
<p>
  Bei aller Offenheit und Vieldeutigkeit besteht aber auch in den traurig-beklemmenden Bildern ein klares Bekenntnis zum
  „Ja“ des Daseins in dieser Welt, verbunden mit dem Hoffnungsschimmer auf eine Zeit, in der diese inhumanen
  Geschehnisse ein Ende haben mögen. Es ist der Aufschein einer vagen Utopie, die aber als positive Heilserwartung
  sogleich wieder verworfen wird. Die große Lyrikerin Ingeborg Bachmann hat diese Paradoxie für ihre eigene Arbeit
  einmal folgendermaßen formuliert: „Ich glaube wirklich an etwas und das nenne ich >Ein Tag wird kommen<. Und eines
  Tages wird es kommen, ja, wahrscheinlich wird es nicht kommen, denn man hat es uns ja immer zerstört. Seit so vielen
  tausend Jahren hat man es immer zerstört. Es wird nicht kommen, und trotzdem glaube ich daran, denn wenn ich nicht
  daran glauben kann, kann ich auch nicht mehr schreiben.“
</p>
<p>
  Jetzt möchte ich aber noch etwas zu der Ästhetik der Bilder, zu dem >„Wie“ sind sie gemalt <, etwas sagen.
</p>
<p>
  Die Entstehung der Bilder beginnt mit dem Aufbau des Bildhintergrunds, einem langsamen Herantasten an die
  farbliche Grundstimmung, die der im Gedächtnis gespeicherten emotionalen Spannung entspricht. Dieser Aufbau kann
  sehr vielschichtig sein und manchmal bis zu zwanzig oder mehr übereinander gelagerte Farbschichten betragen.
</p>
<p>
  Auf dieser farblich beleuchteten Bühne erscheinen dann in der Fortführung ihrer Imagination – in einem ständigen Vor
  und Zurück von Erprobung und Verwerfung – die Figuren. Es ist zunächst wie das schemenhafte Auftauchen von Gestalten
  in der frühmorgendlichen Dämmerung, wo mit zunehmendem Licht die Figuren immer kontrastreicher und farbiger
  aufscheinen. In diesem Stadium haben die Bilder etwas geheimnisvoll Geisterhaftes an sich, etwa so, wie es in diesem
  schwarz-weißen Zyklus bis zum Endstadium erhalten geblieben ist.
</p>
<p>
  Wenn eine möglichst genaue Übereinstimmung mit dem im Gedächtnis gespeicherten Bildgedanken erreicht ist, erfolgt das
  Auftragen eines lebendigen Liniengeflechts, das besondere Merkmal der Jureitschen Bilder.
  Diese Linien, besitzen eine spannungsvolle Doppelfunktion: einerseits klären sie formschaffend die Figur, und auf der
  anderen Seite haben sie in ihrem auf– und abschwellenden, flüssig fließendem Verlauf eine ganz autonome Funktion und
  unterstützen damit den emotionalen Ausdruck des Bildes.
</p>
<p>
  Die beeindruckende Geschlossenheit des Gestaltausdrucks in diesen Bildern wird auch dadurch erreicht, dass alle
  typisierenden oder individualisierenden Merkmale vermieden werden. Es sind anonyme Figuren, entweder ohne oder nur mit
  sparsamen farblichen Andeutungen von Kleidung. Die emotionale Aussage der sich über das ganze Bild erstreckenden
  Gestalt mit ihren Gebärden ist so intensiv, dass auch ein zusätzlicher dementsprechender Ausdruck in der Mimik des
  Gesichts eher störend wirken würde. Es wäre wie eine Verdoppelung der Aussage und damit eine Abschwächung – keine
  Verstärkung. Die Gesichter sind leer, sie haben keine Augen, sodass wir nicht durch eine ständige Fixierung auf die
  Augen von dem Gesamterscheinungsbild der emotionalen Chiffre der Figuren abgelenkt werden.
</p>
<p>
  Ein weiteres Merkmal dieser Bilder ist die prominente lebendige Darstellung der Hände, manchmal sind es sogar mehr,
  als zu einer Figur anatomisch dazu gehören würden. Die Hände haben für die Künstlerin eine große Bedeutung. Es gibt
  frühe Bildzyklen von ihr, auf denen nur Hände zu sehen sind. Ikonologisch ist das nicht schwer zu deuten: Die Hand
  besitzt die einzigartige Doppelfunktion in unserem Verhältnis zur Umwelt: Zum einen tasten, fühlen, begreifen wir mit
  ihr unsere Außenwelt, und zum anderen greifen wir mit ihr handelnd ein, um etwas zu verändern. Der französische
  Philosoph Merleau-Ponty spricht bei dieser gegenseitigen Verflochtenheit mit der Welt von einem Chiasmus, einer
  Überkreuzung, wobei er die gleichzeitige Empfindung von Sehen und Gesehenwerden, von Berühren und Berührtwerden meint.
  Sie können dieses Phänomen an sich selbst beobachten, wenn Sie einmal mit den Fingern der einen Hand über den Rücken
  der anderen streichen.
</p>
<p>
  Bei der hohen Sensibilität der Künstlerin und ihrem starken Willen etwas anzupacken und zu verändern könnte man bei
  diesen Darstellungen der Hände direkt von einem Symbol für ihre eigene Lebenshaltung sprechen.
</p>
<p>
  Die Ausstrahlung der Bilder in diesem sakralen Bau hat auf mich eine fast atemberaubende Wirkung. Es ist nicht nur die
  Raumgröße oder der besondere Lichteinfall, es ist eine ganz besondere Atmosphäre von geistiger Verwandtschaft. Die
  Bilder könnten keinen schöneren Ausstellungsraum finden.
</p>
<p>
  Jetzt ist es an Ihnen vor den Bildern einzutreten in den Chiasmus von Sehen und Gesehenwerden, vor Bildern, die sich
  Ihnen fragend zeigen und auf Ihr suchendes Erkennen warten. Ein Erkennen, das, wie ich schon anfangs gesagt habe,
  nicht auf einen sprachlichen Begriff gebracht werden kann, sondern das in einer Art von Resonanz bei Ihnen eine Saite
  zum Klingen bringen könnte, welche die Sinnhaftigkeit unseres Daseins erahnen ließe.
</p>
<p>
  Ich möchte schließen mit einem kurzen poetischen Text, der eigentlich alles zusammenfasst, was ich in meiner Rede
  versucht habe als Grundthema dieser Bilder darzustellen, nämlich die Erfahrung der eigenen Existenz in der
  Zusammengehörigkeit und der Kommunikation mit anderen Menschen. Und wenn wir bedenken, wo wir uns befinden, fügt
  dieser Text sich auch in weiteren Hinsicht hier ein, er stammt nämlich von John Donne, und John Donne war ein Priester
  der anglikanischen Kirche!
</p>
<p>
  – John Donne, London, 1624:
</p>
<p>
  „No man is an island, entire of itself …..“
</p>
<p>
  Kein Mensch ist eine Insel, ganz sich selbst gehörig,<br />
  jeder Mensch ist ein Stück des Kontinents,<br />
  ein Teil des Ganzen.<br />
  Wenn ein Klumpen Erde ins Meer gespült wird,<br />
  wird Europa weniger,<br />
  genauso als wenn es eine Landzunge wäre,<br />
  oder ein Landgut deiner Freunde<br />
  oder dein eigenes.<br />
  Der Tod jedes Menschen vermindert mich,<br />
  weil ich mit der Menschheit verflochten bin;<br />
  deshalb versuche niemals zu erfahren<br />
  für wen die Glocke läutet;<br />
  - sie läutet für dich.
</p>
<p>
  „The bell tolls for thee.”
</p>
<p>
  Horst Seller, Heidelberg im Juli 2017
</p>
`;

export default text01;
