import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import Page from '../components/Page';
import VideoEmbed from '../components/VideoEmbed';

const StyledContainer = styled(Container)`
  text-align: center;
  h1 {
    margin: 5rem 0 1.5rem 0;
    font-size: 3.75rem;
  }
  video {
    border: 10px solid white;
    background-color: white;
    padding: 0;
    max-width: 53.375rem;
  }
`;

const video = {
  width: '100%',
  height: 'auto',
  poster: '/video/ausdruck-und-gestalt.jpg',
  controls: true,
  autobuffer: true,
  sources: [
    {
      key: 1,
      url: '/video/ingrid_jureit_ausdruck_und_gestalt.mp4',
      type: 'video/mp4',
    },
    {
      key: 2,
      url: '/video/ingrid_jureit_ausdruck_und_gestalt.ogv',
      type: 'video/ogv',
    },
    {
      key: 3,
      url: '/video/ingrid_jureit_ausdruck_und_gestalt.webm',
      type: 'video/webm',
    },
  ],
};

const Film = ({ show = false }) => (
  <Page show={show}>
    <StyledContainer>
      <Row>
        <Col>
          <h1>Film</h1>
          <VideoEmbed video={video} />
        </Col>
      </Row>
      <Row className="py-5 justify-content-center">
        <Col lg={3}>
          <img src="/img/der-mensch-im-bild.jpg" alt="Der Mensch im Bild" />
        </Col>
        <Col lg={3}>
          <img src="/img/intensivseminar-okt-2012.jpg" alt="Intensivseminar Oktober 2012" />
        </Col>
      </Row>
    </StyledContainer>
  </Page>
);

Film.propTypes = {
  show: PropTypes.bool,
};

export default Film;
