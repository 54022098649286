/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import styled, { withTheme } from 'styled-components';

import Page from '../components/Page';
import YoutubeEmbed from '../components/YoutubeEmbed';
import akademie from '../content/akademie';
import gaestebucheintraege from '../content/gaestebucheintraege';

const StyledContainer = styled(Container)`
  font-size: 1.15rem;
  h1 {
    font-size: 2rem;
    margin: 5rem 0 1.5rem 0;
  }
  h2 {
    margin-bottom: 2rem;
  }
  .video {
    display: inline-block;
    background-color: white;
    text-align: center;
    border: 10px solid white;
    .btn {
      padding: 0;
    }
  }
  .entry {
    border-left: 0.75rem solid ${({ theme }) => theme.borderColor};
    padding-left: 2rem;
  }
  .meta {
    font-size: 1rem;
    font-style: italic;
  }
  .text {
    
  }
  @media screen and (min-width: 992px) {
    font-size: 1.35rem;
    h1 {
      font-size: 3.75rem;
    }
  }
`;

const Akademie = ({ show = false }) => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <Page show={show}>
      <StyledContainer>
        <Row>
          <Col className="text-center">
            <h1>Akademie</h1>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <div className="video">
              {showVideo ? (
                <YoutubeEmbed embedId="BS0NhdOlAoM" />
              ) : (
                <Button onClick={() => setShowVideo(true)} variant="link">
                  <img src="/video/youtube-screenshot.jpg" alt="Kreativität und künstlerisches Denken 2017" />
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row className="my-5 justify-content-center">
          <Col lg={8}>
            <div
              className="akademie"
              dangerouslySetInnerHTML={{ __html: akademie }}
            />
          </Col>
        </Row>
        <Row className="my-5 justify-content-center">
          <Col lg={8}>
            <h2>
              Gästebucheinträge Kunstakademie Reichenhall
            </h2>
            {gaestebucheintraege.map((eintrag) => (
              <div className="entry mb-5" key={eintrag.key}>
                <div className="meta">
                  <span>
                    {eintrag.date}
                  </span>
                  <span className="mx-3">
                    {eintrag.author}
                  </span>
                </div>
                <div
                  className="text my-1"
                  dangerouslySetInnerHTML={{ __html: eintrag.text }}
                />
              </div>
            ))}
          </Col>
        </Row>
      </StyledContainer>
    </Page>
  );
};

Akademie.propTypes = {
  show: PropTypes.bool,
};

export default withTheme(Akademie);
