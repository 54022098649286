import PropTypes from 'prop-types';
import React from 'react';
import {
  Transition,
} from 'react-transition-group';
import styled from 'styled-components';
import animation from '../utils/animation';

import Footer from './Footer';
import Line from './Line';
import AppContext from '../utils/AppContext';

const StyledPage = styled.div`
  position: relative;
  background: ${({ theme }) => theme.radialGradient};
  color: ${({ theme }) => theme.color};
  transition: background-color 1s ease;
  transition: color 0.5s ease;
  .content {
    min-height: 10em;
    & > div:first-child {
      padding-top: 0.01em;
    }
  }
`;

const Page = ({ children = null, show = false }) => {
  const ref = React.useRef();

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [children]);

  return (
    <AppContext.Consumer>
      {(value) => (
        <Transition
        // http://reactcommunity.org/react-transition-group/transition
          appear
          in={show}
          nodeRef={ref}
          onEnter={(isAppearing) => animation.play(ref, isAppearing, value)}
          onExit={() => animation.exit(ref)}
          timeout={{ enter: 0, exit: 0 }}
        >
          <StyledPage ref={ref}>
            <Line />
            <div className="content">
              {children}
            </div>
            <Line />
            <Footer />
          </StyledPage>
        </Transition>
      )}
    </AppContext.Consumer>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
};

export default Page;
