import { useEffect, useState } from 'react';

import storage from './storage';

const useTheme = () => {
  const themes = storage.get('themeCollection');
  const [theme, setTheme] = useState(themes.dark);
  const [themeLoaded, setThemeLoaded] = useState(false);

  const setMode = (mode) => {
    storage.set('theme', mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    if (theme && theme.name === 'light') {
      setMode(themes.dark);
    } else {
      setMode(themes.light);
    }
  };

  useEffect(() => {
    const localTheme = storage.get('theme');
    if (localTheme) {
      setTheme(localTheme);
    } else {
      setTheme(themes.dark);
    }
    setThemeLoaded(true);
  }, []);

  return {
    setMode,
    toggleTheme,
    theme,
    themeLoaded,
  };
};

export default useTheme;
