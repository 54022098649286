const akademie = `
<p>
  Seit l988 arbeite ich mit Schülern.
</p>
<p>
  Meine Erfahrungen mit Kunst und wie ich sie erlebe, haben mich dazu bewogen
  ernsthafte überlegungen über künstlerische Bildung und deren Vermittlung anzustellen.
</p>
<p>
  Da sich jeder Kunstschaffende von dem anderen unterscheidet - und das auch muß - ist
  für mich von großer Wichtigkeit, über gezielte individuelle Vorgehensweisen den
  Studierenden immer wieder neue Wege und Möglichkeiten der eigenen Arbeit aufzuzeigen.
</p>
<p>
  Die beratende Funktion ist dabei vielschichtig und den jeweiligen Wandlungen oder
  Neuorientierungen der Schüler angepaßt.
</p>
<p>
  Mein Lehrauftrag an der Kunstakademie Bad Reichenhall umfaßt jährlich mehrere Seminare.
</p>
<p>
  Zusätzliche Informationen zu der Akademie erhalten Sie hier unter:
</p>
<p>
  <a target="_blank" href="https://www.kunstakademie-reichenhall.de">www.kunstakademie-reichenhall.de</a>
</p>
`;

export default akademie;
