import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import Icons from './Icons';

const pages = [
  { alias: 'konzeption', title: 'Konzeption' },
  { alias: 'bilder', title: 'Bilder' },
  { alias: 'vita', title: 'Vita' },
  { alias: 'film', title: 'Film' },
  { alias: 'ausstellungen', title: 'Ausstellungen' },
  { alias: 'akademie', title: 'Akademie' },
  { alias: 'werkstattbuecher', title: 'Werkstattbücher' },
  { alias: 'texte', title: 'Texte' },
  { alias: 'kontakt', title: 'Kontakt' },
];

const StyledNavigation = styled.nav`
  width: 100%;
  position: fixed;
  z-index: 11;
  ul {
    margin: 0;
    padding: 1.5em 0 0 0;
    list-style: none;
    display: none;
    justify-content: center;
    li {
      a {
        display: inline-block;
        padding: 1rem 0.5rem;
        text-decoration: none;
        color: white;
        font-size: 1.7vw;
        line-height: 1.5em;
        transition: color 0.5s ease;
        transition: text-shadow 0.5s ease;
        :before {
          content: "•";
          margin-right: 0.75rem;
          color: rgba(255, 255, 255, 0.3);
        }
        :hover,
        &.active {
          color: #FFEC78;
          text-shadow: 0 0 1em gold;
        }
      }
      :first-child a:before {
        content: "";
      }
    }
  }
  @media screen and (min-width: 992px) {
    z-index: 9;
    ul {
      display: flex;
    }
  }
  @media screen and (min-width: 1400px) {
    ul {
      li {
        a {
          font-size: 1.5em;
        }
      }
    }
  }
`;

const StyledOffcanvas = styled(Offcanvas)`
  z-index: 10000;
  background: ${({ theme }) => theme.radialGradient};
  ul {
    list-style: none;
    flex-direction: column;
    li {
      a {
        color: ${({ theme }) => theme.color};
        text-decoration: none;
        padding: 0.25rem 0;
        display: block;
        font-size: 1.5rem;
        &.active {
          color: ${({ theme }) => theme.highlightColor};
          text-shadow: 0 0 1em ${({ theme }) => theme.glowColor};;
          font-weight: bold;
        }
      }
    }
  }
  .offcanvas-title {
    color: ${({ theme }) => theme.color};
    padding-left: 2rem;
    text-transform: uppercase;
  }
`;

const StyledFooterNavigation = styled.nav`
  ul {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      a {
        color: ${({ theme }) => theme.colorTransparent};
        text-transform: uppercase;
        text-decoration: none;
        margin: 0 0.25em;
        transition: color 0.5s ease;
        :hover {
          color: ${({ theme }) => theme.color};
        }
      }
      :first-child a:before {
        content: "";
      }
    }
  }
  @media screen and (min-width: 992px) {
    ul {
      padding-bottom: 0;
      li {
        a {
          :before {
            content: " • ";
          }
        }
      }
    }
  }
`;

const ToggleButton = styled(Button)`
  position: relative;
  left: 1.5em;
  top: 3em;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  font-weight: bold;
  :hover {
    color: #FFEC78;
    text-shadow: 0 0 1em gold;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const Navigation = () => {
  const [showNav, setShowNav] = useState(false);
  return (
    <>
      <StyledNavigation>
        <ToggleButton onClick={() => setShowNav(!showNav)} variant="link">
          <Icons.Burger />
          MENU
        </ToggleButton>
        <ul className="desktop-nav">
          {pages.map((page) => (
            <li key={page.alias}>
              <NavLink to={`/${page.alias}`}>{page.title}</NavLink>
            </li>
          ))}
        </ul>
      </StyledNavigation>
      <StyledOffcanvas show={showNav} onHide={() => setShowNav(false)}>
        <Offcanvas.Header closeVariant="white" closeButton>
          <Offcanvas.Title>Menü</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="mobile-nav">
            {pages.map((page) => (
              <li key={page.alias}>
                <NavLink onClick={() => setShowNav(false)} to={`/${page.alias}`}>{page.title}</NavLink>
              </li>
            ))}
          </ul>
        </Offcanvas.Body>
      </StyledOffcanvas>
    </>
  );
};

const Footer = () => (
  <StyledFooterNavigation>
    <ul>
      {pages.map((page) => (
        <li key={page.alias}>
          <Link to={`/${page.alias}`}>{page.title}</Link>
        </li>
      ))}
    </ul>
  </StyledFooterNavigation>
);

Navigation.Footer = Footer;

export default withTheme(Navigation);
