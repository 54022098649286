import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Video = styled.div`
  ${'' /* overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  } */}
`;

const YoutubeEmbed = ({ embedId }) => (
  <Video>
    <iframe
      width="854"
      height="480"
      src={`https://www.youtube-nocookie.com/embed/${embedId}`}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="Embedded youtube"
    />
  </Video>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
