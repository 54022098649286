const bilder = [
  // 2020
  {
    key: 1,
    src: '2020-156_jureit.jpeg',
    title: 'Bild Nr. 156/2020, Notiz vom 10.5.2020',
    size: '110 x 90 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 2,
    src: '2020-155_jureit.jpeg',
    title: 'Bild Nr. 155/2020, Notiz vom 3.5.2020',
    size: '90 x 130 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 3,
    src: '2020-154_jureit.jpeg',
    title: 'Bild Nr. 154/2020, Notiz vom 29.4.2020 -Eingesperrt-',
    size: '90 x 120 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 4,
    src: '2020-153_jureit.jpeg',
    title: 'Bild Nr. 153/2020, Notiz vom 20.4.2020 -Ausgegrenzt-',
    size: '100 x 90 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 5,
    src: '2020-151_jureit.jpeg',
    title: 'Bild Nr. 151/2020, Notiz vom 5.3.2020 -Festhalten-',
    size: '80 x 80 cm',
    medium: 'Acryl / Leinwand',
  },
  // {},
  // 2019
  {
    key: 6,
    src: '2019-148_jureit.jpeg',
    title: 'Bild Nr. 148/2019, Notiz vom 12.11.2019 -Wald-',
    size: '120 x 100 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 7,
    src: '2019-146_jureit.jpeg',
    title: 'Bild Nr. 146/2019, Notiz vom Juni 2019',
    size: '100 x 80 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 8,
    src: '2019-145_jureit.jpeg',
    title: 'Bild Nr. 145/2019, Notiz vom Juni 2019 -Festhalten-',
    size: '80 x 60 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 9,
    src: '2019-144_jureit.jpeg',
    title: 'Bild Nr. 144/2019, Notiz vom Juni 2019 -Festhalten-',
    size: '100 x 80 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 10,
    src: '2019-142_jureit.jpeg',
    title: 'Bild Nr. 142/2019, Notiz vom 3.5.2019 2019 -Festhalten-',
    size: '100 x 80 cm',
    medium: 'Acryl / Leinwand',
  },
  // 2017
  {
    key: 11,
    src: '2017-139_jureit.jpeg',
    title: 'Bild Nr. 139/2017, „Zeitnotiz“',
    size: '100 x 80 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 12,
    src: '2017-138_jureit.jpeg',
    title: 'Bild Nr. 138/2017, „Zeitnotiz“',
    size: '110 x 80 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 13,
    src: '2017-132_jureit.jpeg',
    title: 'Bild Nr. 132/2017, „Zeitnotiz“',
    size: '170 x 85 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 14,
    src: '2017-129_jureit.jpeg',
    title: 'Bild Nr. 129/2017, „Zeitnotiz“',
    size: '170 x 85 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 15,
    src: '2017-114_jureit.jpeg',
    title: 'Bild Nr. 114/2016, Tagebuchnotiz vom 20.03.2016',
    size: '100 x 200 cm',
    medium: 'Acryl / Leinwand',
  },
  // 2016
  {
    key: 16,
    src: '2016-126_jureit.jpeg',
    title: 'Bild Nr. 126/2016, Tagebuchnotiz',
    size: '110 x 160 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 17,
    src: '2016-125_jureit.jpeg',
    title: 'Bild Nr. 125/2016, Tagebuchnotiz',
    size: '120 x 100 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 18,
    src: '2016-124_jureit.jpeg',
    title: 'Bild Nr. 124/2016, Tagebuchnotiz',
    size: '120 x 100 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 19,
    src: '2016-113_jureit.jpeg',
    title: 'Bild Nr. 113/2016, Tagebuchnotiz vom 15.03.2016',
    size: '135 x 115 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 20,
    src: '2016-107_jureit.jpeg',
    title: 'Bild Nr. 107/2016, Tagebuchnotiz vom 21.02.2016',
    size: '135 x 115 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 21,
    src: '2016-101_jureit.jpeg',
    title: 'Bild Nr. 101/2016, Tagebuchnotiz vom 27.12.2015',
    size: '120 x 100 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 22,
    src: '2016-100_jureit.jpeg',
    title: 'Bild Nr. 100/2016, Tagebuchnotiz vom 18.12.2015',
    size: '110 x 70 cm',
    medium: 'Acryl / Leinwand',
  },
  // 2015
  {
    key: 23,
    src: '2015-98_jureit.jpeg',
    title: 'Bild Nr. 98/2015, „Wegmakierungen“, Tagebuchnotiz vom 9.11.2015',
    size: '120 x 100 cm',
    medium: 'Acryl / Leinwand',
  },
  // 2014
  {
    key: 24,
    src: '2014-69_jureit.jpeg',
    title: 'Bild Nr. 69/2014, „Selbst schwebend“, Tagebuchnotiz vom 03.01.2014',
    size: '120 x 90 cm',
    medium: 'Acryl / Leinwand',
  },
  // 2013
  {
    key: 25,
    src: '2013-64_jureit.jpeg',
    title: 'Bild Nr. 64/2013, „ich“, Tagebuchnotiz vom 03.12.2013',
    size: '100 x 80 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 26,
    src: '2013-61_jureit.jpeg',
    title: 'Bild Nr. 61/2013, „Kairo Tahirplatz“, Tagebuchnotiz vom 15.08.2013',
    size: '100 x 120 cm',
    medium: 'Acryl / Leinwand',
  },
  {
    key: 27,
    src: '2013-59_jureit.jpeg',
    title: 'Bild Nr. 59/2013, Tagebuchnotiz Februar 2013',
    size: '60 x 70 cm',
    medium: 'Acryl / Leinwand',
  },
  // 2012
  {
    key: 28,
    src: '2012-52_jureit.jpeg',
    title: 'Bild Nr. 52/2012, „Alltag in Syrien“, Tagebuchnotiz vom 16.02.2012',
    size: '71 x 70 cm',
    medium: 'Acryl / Leinwand',
  },
  // Grafiken zu denen der Text soll
  {
    key: 29,
    src: '2013-5_jureit.jpeg',
    title: 'Ohne Titel 2013',
    size: '47 x 30 cm',
    medium: 'Lithografie überarbeitet',
    intro: `
      <h2 class="mt-5">
        Erklärung zu den grafischen Arbeiten:
      </h2>
      <p>
        Diese Arbeiten entstehen zunächst auf der Basis von grafischen selbst angefertigten Drucken<br />
        – wie Radierung, Holzschnitt, oder Lithografien, die dann von mir überarbeitet werden.<br />
        Da mir die Grafik allein nicht langt deutlich zu machen was ich über die dargestellten Menschen zum Ausdruck bringen möchte, überarbeite ich die Drucke jeweils einzeln in einer Mischtechnik, um mit der Übermalung die Individualität der dargestellten Menschen zu betonen. <br />
        Hier ist dann die lineare Übermalung die entscheidende wichtige Aussage, die das Bild dann zum Original macht.
      </p>
      <p>
        Hofheim am Taunus, im Juli 2021
      </p>
    `,
  },
  {
    key: 30,
    src: '2012-4_jureit.jpeg',
    title: 'Ohne Titel 2013',
    size: '43 x 55 cm',
    medium: 'Lithografie überarbeitet',
  },
  {
    key: 31,
    src: '2012-3_jureit.jpeg',
    title: 'Ohne Titel 2012',
    size: '18 x 19 cm',
    medium: 'Holzschnitt überarbeitet',
  },
  {
    key: 32,
    src: '2012-2_jureit.jpeg',
    title: 'Ohne Titel 2012',
    size: '18 x 19,3 cm',
    medium: 'Holzschnitt überarbeitet',
  },
  {
    key: 33,
    src: '2012-1_jureit.jpeg',
    title: 'Ohne Titel 2012',
    size: '18,3 x 19 cm',
    medium: 'Holzschnitt überarbeitet',
  },
  {
    key: 34,
    src: '2012-radierung_jureit.jpg',
    title: 'Ohne Titel 2012',
    size: '14,5 x 10 cm',
    medium: 'Radierung überarbeitet',
  },
  {
    key: 35,
    src: '2008-radierung_jureit.jpg',
    title: 'Ohne Titel 2008',
    size: '15 x 10 cm',
    medium: 'Radierung überarbeitet',
  },
  {
    key: 36,
    src: '2006-2-radierung_jureit.jpg',
    title: 'Ohne Titel 2006',
    size: '14 x 10 cm',
    medium: 'Radierung überarbeitet',
  },
  {
    key: 37,
    src: '2006-1-radierung_jureit.jpg',
    title: 'Ohne Titel 2006',
    size: '15 x 15 cm',
    medium: 'Radierung überarbeitet',
  },
  // 1980 - 1986
  // Aus den achtziger Jahren
  {
    key: 38,
    src: '1986-2_jureit.jpeg',
    title: 'Ohne Titel 1986',
    size: '90 x 60 cm',
    medium: 'Öl / Leinwand',
    intro: '<h2 class="mt-5">Aus den achtziger Jahren</h2>',
  },
  {
    key: 39,
    src: '1986-1_jureit.jpeg',
    title: 'Ohne Titel 1986',
    size: '100 x 70 cm',
    medium: 'Öl / Leinwand',
  },
  {
    key: 40,
    src: '1985-1_jureit.jpeg',
    title: 'Ohne Titel 1985',
    size: '100 x 90 cm',
    medium: 'Öl / Leinwand',
  },
  {
    key: 41,
    src: '1980-1_jureit.jpeg',
    title: 'Ohne Titel 1980',
    size: '74 x 54 cm',
    medium: 'Öl / Leinwand',
  },
  // Bleistiftzeichnungen aus den achtziger Jahren
  {
    key: 42,
    src: '1984-1_jureit.jpeg',
    title: 'Bleistiftzeichnung 1984',
    size: '26 x 35 cm',
    medium: 'Beistift / Papier',
    intro: '<h2 class="mt-5">Bleistiftzeichnungen aus den achtziger Jahren</h2>',
  },
  {
    key: 43,
    src: '1982-1_jureit.jpeg',
    title: 'Porträt einer Freundin 1982',
    size: '39 x 29 cm',
    medium: 'Beistift / Papier',
  },
  {
    key: 44,
    src: '1980-4_jureit.jpeg',
    title: 'Ohne Titel 1980',
    size: '17,5 x 23 cm',
    medium: 'Beistift / Papier',
  },
  {
    key: 45,
    src: '1980-3_jureit.jpeg',
    title: 'Man trägt wieder Kürbis 1980',
    size: '39 x 29 cm',
    medium: 'Beistift / Papier',
  },
  {
    key: 46,
    src: '1980-2_jureit.jpeg',
    title: 'Fragment einer Dauerberieselung 1980',
    size: '25 x 34 cm',
    medium: 'Beistift / Papier',
  },
];

export default bilder;
