import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import ContactForm from '../components/ContactForm';
import Page from '../components/Page';
import werkstattbuecher from '../content/werkstattbuecher';
import schema from '../schema/contact';

const StyledContainer = styled(Container)`
  h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  .bordeux-column {
    background: ${({ theme }) => theme.radialGradientThree};
    color: ${({ theme }) => theme.color};
    padding: 1.5rem;
    order: 1;
  }
  .image-container {
    position: relative;
    order: 2;
    background-color: white;
    border-top: 20px solid white;
    text-align: center;
    img {
      border: 10px solid white;
    }
  }
  .contact-form {
    max-width: 50em;
    .form-control {
      background-color: rgba(255, 255, 255, 0.3);
      color: ${({ theme }) => theme.color};
      margin-bottom: 1rem;
      max-width: 40em;
      &.is-valid {
        background-color: rgba(255, 255, 255, 0.5) !important;
      }
    }
    .form-check-input {
      min-width: 1em;
    }
    .form-check-label {
      margin-left: 1rem;
    }
    textarea {
      height: 10rem;
    }
    .btn {
      margin-top: 1.5em;
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color};
      color: ${({ theme }) => theme.color};
      display: inline-flex;
      align-items: center;
      transition: text-shadow 0.5s ease;
      svg {
        margin-right: 0.5rem;
        transition: fill 0.5s ease;
        path {
          fill: ${({ theme }) => theme.color};
        }
      }
      &:hover {
        color: ${({ theme }) => theme.highlightColor};
        border-color: ${({ theme }) => theme.highlightColor};
        text-shadow: 0 0 1em ${({ theme }) => theme.glowColor};
        svg {
          path {
            fill: ${({ theme }) => theme.highlightColor};
          }
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    h1 {
      font-size: 3.75rem;
    }
    .image-container {
      order: 1;
      background-color: transparent;
      border-top: none;
      min-height: 56.25rem;
      img {
        position: absolute;
        top: 12em;
        right: -5em;
        max-width: 24rem;
      }
    }
    .bordeux-column {
      padding: 6em 12em 6em 10em;
      order: 2;
    }
  }
  @media screen and (min-width: 1400px) {
    .image-container {
      img {
        max-width: 32rem;
      }
    }
  }
`;

const send = async (values, actions, setSent) => {
  try {
    await axios.post('/api/contact', values);
    setSent(true);
  } catch (e) {
    actions.setErrors(e.response.data.errors);
  } finally {
    actions.setSubmitting(false);
  }
};

const Kontakt = ({ show = false }) => {
  const [sent, setSent] = useState(false);
  const location = useLocation();
  const { initialValues } = schema;
  if (location.hash) {
    const key = location.hash.substring(1);
    const index = _.findIndex(werkstattbuecher, (o) => o.key === parseInt(key, 10));
    if (index !== -1) {
      const book = werkstattbuecher[index];
      const title = book.title.replace(/<br \/>/g, ' - ');
      initialValues.subject = `Werkstattbuch: ${(title || '')}`;
      initialValues.message = `Sehr geehrte Frau Jureit,
  
hiermit bestelle ich ein Exemplar ihres Werkstattbuchs
${(title || '')}.
        `;
    }
  }

  return (
    <Page show={show}>
      <StyledContainer fluid>
        <Row>
          <Col className="image-container" lg={4}>
            <img src="/img/ingridjureit-vita.jpg" alt="Ingrid Jureit Portrait" />
          </Col>
          <Col className="bordeux-column" lg={8}>
            <h1>
              Kontakt
            </h1>
            <p>
              Ingrid Jureit
            </p>
            <p>
              Wielandstraße 24 B
              <br />
              65719 Hofheim am Taunus
            </p>
            <p>
              Tel.: 06192 26772
            </p>
            <p>
              E-Mail: mail@ingridjureit.de oder nutzen Sie das Kontaktformular.
            </p>
            {sent ? (
              <Alert variant="info">
                Vielen Dank!
              </Alert>
            ) : (
              <div className="contact-form">
                <ContactForm
                  initialValues={initialValues}
                  onSubmit={(values, actions) => send(values, actions, setSent)}
                />
              </div>
            )}
          </Col>
        </Row>
      </StyledContainer>
    </Page>
  );
};

Kontakt.propTypes = {
  show: PropTypes.bool,
};

export default withTheme(Kontakt);
