const ausstellungen = [
  {
    key: 34,
    date: '2024',
    text: `
      <p>
        Stadtmuseum Hofheim am Taunus  (E)  Katalog
      </p>
    `,
  },
  {
    key: 1,
    date: '2020',
    text: `
      <p>
        Ausstellungsbeteiligung Kunstforum Seligenstadt e.V.<br />
        Galerie im Alten Haus 63500 Seligenstadt
      </p>
    `,
  },
  {
    key: 2,
    date: '2019',
    text: `
      <p>
        Ausstellungsbeteiligung Galerie am Tor,<br />
        in 63897 Miltenberg am Main
      </p>
    `,
  },
  {
    key: 3,
    date: '2017',
    text: `
      <p>
        Englische Kirche Bad Homburg v.d.H. (E) Katalog
      </p>
    `,
  },
  {
    key: 4,
    date: '2015',
    text: `
      <p>
        Kunsthaus Wiesbaden Gemeinschaftsausstellung BBK Wiesbaden,<br />
        Kunstforum Mainturm Flörsheim - Gemeinschaftsausstellung Gruppe "nonagon"-,
      </p>
      <p>
        Zusammenarbeit mit der Galerie für moderne Kunst<br />
        Abteigasse 1, 63916 Amorbach, "Mainart", 63906 Erlenbach a. Main,<br />
        Internationale Messe für moderne Kunst.
      </p>
    `,
  },
  {
    key: 5,
    date: '2014',
    text: `
      <p>
        Stadtmuseum Hofheim am Taunus (E) Katalog<br />
        Galerie im Saal - Museum Schloss Oberschwappach Künstlergruppe "nonagon" Main-Taunus-Kreis
      </p>
      <p>
        Galerie für moderne Kunst Cornelia König-Becker Amorbach (E) Katalog
      </p>
    `,
  },
  {
    key: 6,
    date: '2013',
    text: `
      <p>
        Produzenten Galerie Landshut (E) Katalog<br />
        Sonnabend-Galerie Ulla Teschner Hofheim/Taunus (E) Katalog
      </p>
      <p>
        Main Taunus-Galerie Hofheim/Taunus
      </p>
      <p>
        Dozenten- Ausstellung Galerie altes Feuerhaus Bad - Reichenhall
      </p>
    `,
  },
  {
    key: 7,
    date: '2012',
    text: `
      <p>
        Aktion "Zum Gedenken an Fukushima"<br />
        Heidelberger Freundeskreis Kumamoto, Heidelberg
      </p>
      <p>
        Galerie "Maria Kreuzer", Amorbach
      </p>
    `,
  },
  {
    key: 8,
    date: '2011',
    text: '<p>Englische Kirche Bad Homburg v.d.H.  (E) Katalog</p>',
  },
  {
    key: 9,
    date: '2010',
    text: '<p>Beteiligung an Gruppenausstellung "Mainzer Kunst", Mainz</p>',
  },
  {
    key: 10,
    date: '2009',
    text: '<p>Beteiligung an Gruppenausstellungen in verschieden Galerien</p>',
  },
  {
    key: 11,
    date: '2008',
    text: '<p>Galerie "Mainzer Kunst", Mainz</p>',
  },
  {
    key: 12,
    date: '2007',
    text: `
      <p>
        Galerie "kunst im souterrain", Wiesbaden<br />
        dsART Galerie, Frankfurt am Main
      </p>
    `,
  },
  {
    key: 13,
    date: '2006',
    text: `
      <p>
        "Artbreit", Kunstfest Marktbreit
      </p>
      <p>
        Zusammenarbeit mit der Galerie Norbert Blaeser, Steffeln Vulkaneifel<br />
        art Karlsruhe - Internationale Messe für Moderne Kunst
      </p>
    `,
  },
  {
    key: 14,
    date: '2005',
    text: `
      <p>
        Zusammenarbeit mit der Galerie Norbert Blaeser, Steffeln Vulkaneifel<br />
        art Karlsruhe - Internationale Messe für Moderne Kunst
      </p>
    `,
  },
  {
    key: 15,
    date: '2004',
    text: `
      <p>
        Zusammenarbeit mit der Galerie Norbert Blaeser, Steffeln Vulkaneifel<br />
        art Karlsruhe - Internationale Messe für Moderne Kunst - (Katalog)
      </p>
    `,
  },
  {
    key: 16,
    date: '2002',
    text: '<p>Stadtmuseum, Hofheim am Taunus - 4o-Jahre Hofheimer Gruppe ­ (Katalog)</p>',
  },
  {
    key: 17,
    date: '2001',
    text: `
      <p>
        Stadtmuseum der Kreisstadt Hofheim am Taunus "Kunst im Gespräch" Galerie im Kreishaus, Hofheim am Taunus<br />
        Galerie Norbert Blaeser, Steffeln Vulkaneifel<br />
        - in Kooperation mit der Akademie für bildende Kunst Vulkaneifel ­"Kultursommer Rheinland Pfalz"
      </p>
    `,
  },
  {
    key: 18,
    date: '2000',
    text: '<p>Thonet-Forum, Frankfurt am Main (E)</p>',
  },
  {
    key: 19,
    date: '1999',
    text: '<p>Rathausfoyer, Hofheim am Taunus - Jahresausstellung Hofheimer Gruppe</p>',
  },
  {
    key: 20,
    date: '1998',
    text: '<p>Englische Kirche, Bad Homburg v.d.H. (E)</p>',
  },
  {
    key: 21,
    date: '1997',
    text: '<p>Galerie im Kreishaus, Hofheim am Taunus - Hofheimer Gruppe - (Katalog)</p>',
  },
  {
    key: 22,
    date: '1996',
    text: '<p>Aktion Offenes Atelier</p>',
  },
  {
    key: 23,
    date: '1995',
    text: `
      <p>
        Galerie im Kreishaus, Hofheim am Taunus (E)<br />
        Galerie "Raum für Kunst" Frankfurt am Main (E) (Katalog)<br />
        Galerie Stadthaus, Bad Homburg v.d.H, (Künstlerbund Taunus)
      </p>
    `,
  },
  {
    key: 24,
    date: '1994',
    text: `
      <p>
        Galerie König - Künstlerhaus Hochheim - Massenheim (E)<br />
        Galerie im Stadthaus Bad Homburg v.d.H. (Künstlerbund Taunus)<br />
        Fabrikgelände "Eden", Bad Soden im Taunus
      </p>
    `,
  },
  {
    key: 25,
    date: '1993',
    text: `
      <p>
        Englische Kirche, Bad Homburg v,d.H, (E)<br />
        Galerie Junker, Hofheim am Taunus
      </p>
    `,
  },
  {
    key: 26,
    date: '1992',
    text: '<p>Stadtmuseum, Hofheim am Taunus - Hofheimer Gruppe - (Katalog)</p>',
  },
  {
    key: 27,
    date: '1991',
    text: '<p>Rathausfoyer, Hofheim am Taunus - Hofheimer Gruppe­</p>',
  },
  {
    key: 28,
    date: '1990',
    text: '<p>Galerie Jordy, Frankfurt am Main</p>',
  },
  {
    key: 29,
    date: '1988',
    text: '<p>Kunststation Kleinsassen/Rhön - mit Gruppe 50 Wiesbaden - (Katalog)</p>',
  },
  {
    key: 30,
    date: '1987',
    text: '<p>Werkschau zur Erstellung einer Lithographie - Kunstmappe für die Stadt Hofheim am Taunus</p>',
  },
  {
    key: 31,
    date: '1986',
    text: '<p>Berufsverband Bildender Künstler Frankfurt in Graz / Österreich (Katalog)</p>',
  },
  {
    key: 32,
    date: '1985',
    text: '<p>Rathausfoyer, Hofheim am Taunus (E)</p>',
  },
  {
    key: 33,
    date: '1984',
    text: '<p>Internationale Ausstellung England-Frankreich-Deutschland in Chinon (Frankreich)</p>',
  },
];

export default ausstellungen;
