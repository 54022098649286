import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: 'Bitte korrigieren Sie dieses Feld.',
    required: 'Bitte füllen Sie dieses Feld aus',
  },
  string: {
    email: 'Bitte geben Sie eine gültige Email-Adresse ein.',
    min: ({ min }) => `Bitte geben Sie mindestens ${min} Zeichen ein`,
  },
});

export default yup;
