import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import Icons from './Icons';
import Logo from './Logo';
import Navigation from './Navigation';

const StyledHeader = styled.div`
  height: 22.75rem;
  background: url(/img/installation.jpg) no-repeat center center;
  background-size: cover;
  .logo-button {
    position: absolute;
    bottom: 2.25rem;
    left: 50%;
    width: 100%;
    max-width: 150px;
    margin-left: -75px;
    text-align: center;
    img {
      transition: transform 0.3s ease;
    }
    &:hover {
      img {
        transform: scale(1.2, 1.2);
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .logo-icon-button {
    position: absolute;
    top: 2rem;
    left: 8rem;
    z-index: 10;
    svg {
      width: 2rem;
      height: auto;
      transition: transform 0.3s ease;
    }
    &:hover {
      svg {
        transform: scale(1.2, 1.2);
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .theme-toggle {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    opacity: 0.5;
    transition: opacity 0.5s ease;
    z-index: 10;
    :hover {
      opacity: 1;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  @media only screen and (min-width: 992px) {
    height: 52.56rem;
    .logo-icon-button {
      left: 0;
    }
    .logo-button {
      bottom: 3.125rem;
      left: 0;
      margin-left: 0;
      max-width: 100%;
    }
    .theme-toggle {
      right: 0;
    }
  }
  @media only screen and (min-width: 1100px) {
    .logo-icon-button {
      left: 1rem;
    }
    .theme-toggle {
      right: 1rem;
    }
  }
  @media only screen and (min-width: 1400px) {
    .logo-icon-button {
      left: 2rem;
    }
    .theme-toggle {
      right: 2rem;
    }
  }
`;

const Header = ({ toggleTheme }) => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const element = ref.current;
    const logo = element.querySelector('.logo-button');
    const logoIcon = element.querySelector('.logo-icon-button');
    const themeToggle = element.querySelector('.theme-toggle');
    const mq = window.matchMedia('(min-width: 992px)');
    const logoY = mq.matches ? -800 : -750;
    const themeToggleY = mq.matches ? -785 : -330;
    const scrollEnd = mq.matches ? 1000 : 500;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        pin: true,
        pinSpacing: false,
        start: 'top top',
        end: `+=${scrollEnd}`,
        scrub: 0.2,
        // markers: true,
        // onUpdate: (self) => console.log(self),
      },
    });
    tl.to(logo, {
      autoAlpha: 0,
      y: logoY,
      duration: 2,
    });
    tl.from(logoIcon, {
      autoAlpha: 0,
      x: -100,
      duration: 2,
    }, '-=2');
    tl.to(themeToggle, {
      y: themeToggleY,
      duration: 2,
    }, '-=2');
  }, []);

  const scrollToTop = () => {
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.2,
      // ease: 'Power1.easeInOut',
    });
  };

  return (
    <StyledHeader ref={ref}>
      <Button
        className="logo-icon-button"
        variant="link"
        onClick={() => scrollToTop()}
      >
        <Logo.Icon />
      </Button>
      <Navigation />
      <Button
        className="logo-button"
        variant="link"
        onClick={() => scrollToTop()}
      >
        <Logo variant="dark" />
      </Button>
      <Button className="theme-toggle" variant="link" onClick={toggleTheme}>
        <Icons.Contrast />
      </Button>
    </StyledHeader>
  );
};

Header.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
};

export default Header;
