import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

// eslint-disable-next-line no-unused-vars
const play = (ref, isAppearing, value) => {
  const { init, setInit } = value;
  const content = ref.current.querySelector('.content');

  let scrollTo = 0;
  if (init) {
    scrollTo = { y: '.content', offsetY: 160 };
  }

  // eslint-disable-next-line no-unused-vars
  const scroll = () => {
    const tl = gsap.timeline();
    tl.to(window, {
      scrollTo,
      duration: 0.5,
      ease: 'Power4.easeOut',
      onComplete: setInit(true),
    });
    return tl;
  };

  const fadeIn = () => {
    const tl = gsap.timeline();
    tl.from(content, {
      autoAlpha: 0,
      duration: 0.15,
      y: 25,
      ease: 'Power1.easeInOut',
    });
    return tl;
  };

  const master = gsap.timeline({ paused: true });
  master.add(scroll());
  master.add(fadeIn());

  window.loadPromise = new Promise(() => {
    requestAnimationFrame(() => master.play());
  });
};

const exit = (ref) => {
  const timeline = gsap.timeline({ paused: true });

  timeline.to(ref.current, {
    autoAlpha: 0,
    duration: 0.15,
    ease: 'Power1.easeOut',
  });
  timeline.play();
};

export default {
  play,
  exit,
};
