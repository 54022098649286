import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Header from './components/Header';
import Akademie from './pages/Akademie';
import Ausstellungen from './pages/Ausstellungen';
import Bilder from './pages/Bilder';
import Datenschutz from './pages/Datenschutz';
import Film from './pages/Film';
import Impressum from './pages/Impressum';
import Kontakt from './pages/Kontakt';
import Konzeption from './pages/Konzeption';
import NoMatch from './pages/NoMatch';
import Texte from './pages/Texte';
import Vita from './pages/Vita';
import Werkstattbuecher from './pages/Werkstattbuecher';
import * as themes from './themes.json';
import AppContext from './utils/AppContext';
import storage from './utils/storage';
import useTheme from './utils/useTheme';

function App() {
  const { theme, themeLoaded, toggleTheme } = useTheme();
  const [selectedTheme, setSelectedTheme] = React.useState(theme);
  const [init, setInit] = React.useState(false);
  storage.set('themeCollection', themes.default);

  React.useEffect(() => {
    setSelectedTheme(theme);
  }, [theme]);

  const appContext = React.useMemo(() => ({
    init,
    setInit,
  }), [init]);

  return themeLoaded ? (
    <AppContext.Provider value={appContext}>
      <ThemeProvider theme={selectedTheme || themes.default.dark}>
        <Router>
          <Header toggleTheme={toggleTheme} />
          <Routes>
            <Route path="datenschutz" exact element={<Datenschutz show />} />
            <Route path="impressum" exact element={<Impressum show />} />
            <Route path="kontakt" exact element={<Kontakt show />} />
            <Route path="texte" exact element={<Texte show />} />
            <Route path="werkstattbuecher" exact element={<Werkstattbuecher show />} />
            <Route path="akademie" exact element={<Akademie show />} />
            <Route path="ausstellungen" exact element={<Ausstellungen show />} />
            <Route path="film" exact element={<Film show />} />
            <Route path="vita" exact element={<Vita show />} />
            <Route path="bilder" exact element={<Bilder show />} />
            <Route path="konzeption" exact element={<Konzeption show />} />
            <Route path="/" exact element={<Navigate to="/konzeption" replace />} />
            <Route element={<NoMatch />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AppContext.Provider>
  ) : <div>Loading...</div>;
}

export default App;
